import React from "react";
import PropTypes from "prop-types";
import URL from "url";
import axios from "axios";
import lodash from "lodash";

import {Helmet} from "react-helmet";
import Grid from "@material-ui/core/Grid";
import Hidden from '@material-ui/core/Hidden';
import { FaFacebookF, FaYoutube, FaTwitter, FaInstagram } from 'react-icons/fa';

import { withStyles } from '@material-ui/core/styles';
import Footer from "./../../base/components/Footer"
import {
  Header, Banners, TwitterFeed, Section,
  AlumniNews, AlumniMap, DistinguishedAlumni, UpcomingEvents,
  RecentMemories, NetworkAndLogin, ContentContainer, PullupContent, NewsLetter, Announcements,
} from "./../../base/components/CommonComponents";
//import metadata from "./metadata";
import defaultMetadata from "./../../base/defaultMetadata";

const styles = (theme) => ({
  footerContainer: {
    maxWidth: 1156,
    margin: 'auto',
    padding: '0 32px',
    color: defaultMetadata.theme.colors.battleshipGrey,
  },
  footerDivider: {
    backgroundColor: defaultMetadata.theme.colors.coolGrey,
    height: 1,
  },
  footerLinkSaperator: {
    display: "inline-block",
    marginLeft: 8,
    marginRight: 8,
  },
  footerToggleAlign: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-end',
      textAlign: 'right',
    }
  },
});

class Root extends React.Component {

  constructor(props){
    super(props);
  }

  renderFooter = () => {
    const {classes} = this.props;
    const {metadata:{logo:{url: logo_url}, twitter_link, facebook_link, instagram_link, youtube_link}} = this.props;
    const {metadata} = this.props;
    return (
      <div style={{lineHeight: 1.5, marginTop: 40}}>
        <div className={classes.footerDivider} style={{height: 2}}></div>
        <div className={classes.footerDivider} style={{marginTop: 2, marginBottom: 24}}></div>
        <div className={classes.footerContainer}>
          <Grid container justify="center">
            <Footer metadata={metadata} />
          </Grid>
        </div>
      </div>
    );
  };

  renderVideos = () => {
    const {metadata:{videos}} = this.props;

    let videoComponents = videos.map((video, index) => {
      return (
        <Grid item sm={12} md={6} style={{width: "100%"}} key={index}>
          <Section sectionHeading={video.title}>
            <iframe width="100%"
                    style={{minHeight: 200}}
                    src={video.url}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title={video.title}
            ></iframe>
          </Section>
        </Grid>
      );
    });

    return (
      <div style={{marginTop: 24}}>
        <ContentContainer>
          <Grid container direction="row" spacing={24} justify="center" alignItems="center">
            {videoComponents}
          </Grid>
        </ContentContainer>
      </div>
    );
  }


  render() {
    const {metadata} = this.props;

    var favicon = "";
    if(metadata.favicon){
      favicon = metadata.favicon.url;
    }
    return (
     <React.Fragment>
       <Helmet>
         <title>{metadata.page_title}</title>
         <link rel="shortcut icon" href={favicon} />
       </Helmet>

       <Header metadata={metadata} />
       <Banners metadata={metadata} />

       {this.renderVideos()}

       <ContentContainer><NetworkAndLogin metadata={metadata} /></ContentContainer>
       <Announcements metadata={metadata} />
       <NewsLetter metadata={metadata} />
       <AlumniMap metadata={metadata}></AlumniMap>

       <PullupContent pullupSize={150}>
         <ContentContainer>
           <div style={{padding: 12}}>
             <Grid container spacing={24} justify="center">

               <Grid item sm={12} md={7}>
                 <AlumniNews metadata={metadata} style={{height: "100%"}} scrollHeight={934}/>
               </Grid>

               <Grid item sm={12} md={5}>
                 <Grid container direction="column">

                   <Grid item>
                     <DistinguishedAlumni metadata={metadata} scrollHeight={metadata.twitter_feed_enabled ? undefined:934}/>
                   </Grid>

                   <Grid item style={{marginTop: metadata.distinguished_alumni_enabled ? 24 : 0}}>
                     <TwitterFeed metadata={metadata} scrollHeight={metadata.distinguished_alumni_enabled ? undefined:934} scrollHeight={934}/>
                   </Grid>

                 </Grid>
               </Grid>

             </Grid>
           </div>
         </ContentContainer>
       </PullupContent>

       <ContentContainer>
         <div style={{padding: 12}}>
           <Grid container spacing={24} justify="center">
             <Grid item sm={12} md={7} style={{width: "100%"}}>
               <UpcomingEvents metadata={metadata} style={{height: "100%"}} />
             </Grid>
             <Grid item sm={12} md={5} style={{width: "100%"}}>
               <RecentMemories metadata={metadata} style={{height: "100%"}} />
             </Grid>
           </Grid>
         </div>
       </ContentContainer>
       {this.renderFooter()}
     </React.Fragment>
   );
  }
}


export default withStyles(styles)(Root);
