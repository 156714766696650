import React, { Component } from 'react';
import PropTypes from 'prop-types';
import URL from "url";
import axios from "axios";
import lodash from "lodash";

import 'normalize.css';
import './css/app.scss';

import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Root from "./../networks/IITD/Root";

const styles = {
  root: {
    height: "100%",
    width: "100%",
    maxWidth: 1280,
    margin: "auto",
    backgroundColor: "rgb(243, 244, 245)",
    minHeight: "100vh",
    fontFamily: ["Nunito Sans", "Roboto", "Helvetica", "Arial", "sans-serif"],
  }
};

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  }
});

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      metadata: []
    };
  }
  componentDidMount(){
    axios.get("https://www.almaconnect.com/api/metadata.json?website=" + window.location.href).then((response) => {
      this.setState({metadata: response.data.data});
    });

  }

  render() {
    const {classes} = this.props;
    if(this.state.metadata.name === undefined) return null;
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <Root metadata = {this.state.metadata}/>
        </div>
      </MuiThemeProvider>
    );
  }

}


App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
