import React from 'react';
import PropTypes from 'prop-types';
import URL from 'url';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';

import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import classnames from "classnames";
import DropItem from "./DropItem";
import defaultMetadata from "./../defaultMetadata";


const styles = {
  headerRoot: {
    background: "#fff",
  },
  headerContent: {
    maxWidth: 1156,
    margin: 'auto',
    height: 80,
    padding: '0 32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between",
  },
  subGrid: {
    width: "auto",
  },
  logo: {
    maxHeight: 64,
    marginRight: 24,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  networkTitle: {
    fontWeight: 700,
    fontSize: 24,
  },
  titleCaption: {
    fontSize: 16,
    lineHeight: '1.5em',
  },
  menuDrawerContainer: {
    width: 200
  },
  menuIcon: {
    cursor: 'pointer'
  },
  menuLink: {
    color: defaultMetadata.theme.colors.battleshipGrey,
  },
  menuLinkExpanded: {
    marginLeft: 16,
    marginRight: 16,
  },
};


class Header extends React.Component {
  constructor(){
    super();
    this.state = {
    menuOpen: false,
    dropOpen: false,
  };
  this.toggleDropdownMenu = this.toggleDropdownMenu.bind(this);
  this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  };
  toggleDropdownMenu(event) {
    event.preventDefault();
    this.setState({ dropOpen: true }, () => {
    document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ dropOpen: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });
  }

  toggleMenuDrawer = (open) => () => {
    this.setState({menuOpen: open});
  }

  renderLogoAndTitle() {
    const {classes, metadata} = this.props;
    const {logo, primary_header_title, secondary_header_title} = metadata;

    return (
      <React.Fragment>
        <Grid item>
          <a href="/index.html">
            <img className="header-logo" src={logo.url} alt="logo" />
          </a>
        </Grid>
        <Grid item className={classes.titleContainer}>
          <Grid item>
            <a href="/" className="network-title">{primary_header_title}</a>
          </Grid>
          <Grid item>
            <a href="/" className="title-caption">{secondary_header_title}</a>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  renderMenuAndLoginExpanded() {
    const {classes, metadata: {main_menu_items, network_base_url, institute_list, multiple_institutes}} = this.props;
    const menuItemComponents = main_menu_items.map((menuItem, index) => {
      if(!menuItem.dropdown){
        return (
            <Grid item key={index} className={classnames(classes.menuLink, classes.menuLinkExpanded)}>
              <a href={menuItem.menuItemList[0].url} target="_blank"className="ac_link">{menuItem.menuItemList[0].name}</a>
            </Grid>
        );
      }
      else{
        return(
          <DropItem index={index} menuItem={menuItem}/>
       )
      }
    });
    const loginComponent = (
      <Grid item key="login" style={{marginLeft: 16}}>
        <div className="ac_button">
          <a href={network_base_url}>Login/Signup</a>
        </div>
      </Grid>
    );
    const loginComponentMult = (
      <Grid item className = "dropdown">
        <div className = "ac_button" style={{padding: "12px 68px"}} onClick = {this.toggleDropdownMenu}>Login/Signup</div>
          {
            this.state.dropOpen ? (
            <ul className = "drop_ul">{institute_list.map((inst, index)=>(
              <a className = "drop_a" href = {inst.url}><li className = "drop_li">{inst.name}</li></a>))}
            </ul>
          ): (null)
        }
      </Grid>
    );

    return (
        <React.Fragment>
          {menuItemComponents}
          {multiple_institutes ? (loginComponentMult) : (loginComponent)}
        </React.Fragment>
    );
  };

  renderMenuAndLoginList() {
    const {classes, metadata: {main_menu_items, network_base_url,institute_list, multiple_institutes}} = this.props;

    const menuItemComponents = main_menu_items.map((menuItem, index) => {
        if(!menuItem.dropdown)
          {
            return(
            <ListItem key={index} className={classes.menuLink} button component="a" href={menuItem.menuItemList[0].url} >
            <ListItemText primary={menuItem.menuItemList[0].name} />
          </ListItem>
          )
        }
        else{
          return(
            <DropItem index={index} menuItem={menuItem} mobile={true}/>
         )
        }
    });
    const loginComponent = (
      <ListItem key="login" button component="a" href={network_base_url} className={classes.menuLink}>
        <ListItemText primary="Login/Signup" />
      </ListItem>
    );

    const loginComponentMult = (
      <Grid item >
        <div className = {classnames("ac_button")}  onClick = {this.toggleDropdownMenu}>Login/Signup</div>
          {this.state.dropOpen ? (
            <ul className = "drop_ul">{institute_list.map((inst, index)=>(
              <a className = "drop_a" href = {inst.url}><li className = "drop_li">{inst.name}</li></a>))}
            </ul>
          ): (null)
        }
      </Grid>
    );

    return (
      <div className={classes.menuDrawerContainer}>
        <List>{menuItemComponents}</List>
        <Divider />
        {multiple_institutes ? (<List>{loginComponentMult}</List>) : (<List>{loginComponent}</List>)}
      </div>
    );
  };

  renderMenuAndLogin() {
    const {classes} = this.props;
    if (isWidthUp("md", this.props.width)) {
      return this.renderMenuAndLoginExpanded();
    } else {
      const list = this.renderMenuAndLoginList();
      return (
        <React.Fragment>
          <IconButton className={classes.menuIcon} onClick={this.toggleMenuDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Drawer anchor="right" open={this.state.menuOpen} onClose={this.toggleMenuDrawer(false)}>
            {list}
          </Drawer>
        </React.Fragment>
      );
    }
  };

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.headerRoot}>
        <div className="header-content">

          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid container className={classes.subGrid}>
              {this.renderLogoAndTitle()}
            </Grid>
            <Grid container className={classes.subGrid} alignItems="center">
              {this.renderMenuAndLogin()}
            </Grid>
          </Grid>

        </div>
      </div>
    );
  }

}



Header.propTypes = {
  metadata: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    network_base_url: PropTypes.string.isRequired,
    mainMenuItems: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export default withWidth()(withStyles(styles)(Header));
